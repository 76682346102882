// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---themes-gatsby-theme-blog-src-pages-blog-tsx": () => import("./../../../../themes/gatsby-theme-blog/src/pages/blog.tsx" /* webpackChunkName: "component---themes-gatsby-theme-blog-src-pages-blog-tsx" */),
  "component---themes-gatsby-theme-blog-src-templates-post-tsx": () => import("./../../../../themes/gatsby-theme-blog/src/templates/post.tsx" /* webpackChunkName: "component---themes-gatsby-theme-blog-src-templates-post-tsx" */)
}

